import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/Interface/CommonModule/TableAndPage'

import { LoanRePayMentStatiSticsForm, LoanRepaymentLoanListForm, LoanLabelListForm } from '@/Interface/BankModule/index'
export class RePayMent { //关于还款的
    public getLoanRePayMentList(id: string, page: HandleTable, cb: Function) { //获取还款记录
        new BasicsClass().BasicGet('/animal/web/loanRepayment/list', { id, ...page }, false, false, cb)
    }
    public addOneLoanRePayMent(id: string, repaymentAmount: string, cb: Function) { //还款
        new BasicsClass().BasicPost1("/animal/web/loanRepayment/addOne", { id, repaymentAmount }, true, true, true, cb)
    }
    public getBaseLoanRePayMent(id: string, cb: Function) { //获取还款前基本信息
        new BasicsClass().BasicGet('/animal/web/loanRepayment/getBase', { id }, false, false, cb)
    }
}
export class GuaranteeBusinessBk { //关于担保业务
    //获取数据汇总
    public getLoanRePayMentStatiStics(form: LoanRePayMentStatiSticsForm, cb: Function) { //获取还款记录
        new BasicsClass().BasicGet('/animal/web/loanRepayment/statistics', { ...form }, false, false, cb)
    }
    //获取银行列表
    public getBankList(cb: Function) { //获取担保看板的银行列表数据
        new BasicsClass().BasicGet('/animal/web/loanRepayment/bankList', {}, false, false, cb)
    }
    //获取养殖场列表
    public getFarmList(cb: Function) {
        new BasicsClass().BasicGet('/animal/web/loanRepayment/farmList', {}, false, false, cb)
    }
    //获取放款记录
    public getLoanRepaymentLoanList(form: LoanRepaymentLoanListForm, page: HandleTable, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/loanRepayment/loanList', { ...form, ...page }, false, false, cb)
    }
    //获取贷款记录信息
    public getloanDetail = (id: string, cb: Function) => {
        new BasicsClass().BasicGet("/animal/web/loanAudit/loanDetail", { id }, false, false, cb)
    }
    //导出放款记录
    public exportRecordList(form: LoanRepaymentLoanListForm) {
        new BasicsClass().getFile('/animal/web/loanRepayment/export/loanList', { ...form })
    }
    //耳标详情
    public getloanLabelList = (form: LoanLabelListForm, page: HandleTable, cb: Function) => {
        new BasicsClass().BasicGet("/animal/web/loanRepayment/labelList", { ...form, ...page }, false, false, cb)
    }
}

//担保公司相关接口
export class GuaranteeCompanyAPI {
    /**@param  getGuaranteeCompany  获取担保公司列表*/
    public getGuaranteeCompany(cb: Function) { //获取担保公司列表
        new BasicsClass().BasicGet('/animal/web/loanAudit/getGuaranteeCompany', {}, false, false, cb)
    }
}




export class BankModule {
    public getBigScreenBankNewVideoGroupCodeV333 = (animalTypeId: number | string, bankId: number | string, cb: Function) => { //银行大屏V333  视频 组织机构
        new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/video/bankGroupCodeV333', { animalTypeId, bankId }, false, false, cb)
    }
    public getBigScreenBankNewVideoListV333 = (form: any, page: any, cb: Function) => {//银行大屏V333  视频 
        new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/videoListV333', { ...form, ...page, streamType: 1 }, false, false, cb, () => { }, false)
    }
}