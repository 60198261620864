




















































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util } from "@/common/Views/comm/util";
import { BasicsClass } from "../../../common/BasicsClass";
import {
    BigData,
    FrameData,
    Temperature,
    SlaughterStatic,
    SmartInventory,
    SelectPopInt,
    Frame,
    VideoList,
} from "@/common/Views/bigData/bigData";

import { DeepLoop } from "@/common/deepLoop/deepLoop";
import axios from "axios";
import {
    getBigScreenBankNewVideoGroupCode,
    getBigScreenBankNewVideoList,
    getOldBankCurrentBankfiliale315,
    bankGroupCodeNav315,
    judgeExistWarninLightAuth,
} from "@/api/index";
import { BankModule } from "@/api/BankModule/index";
@Component({
    components: {
        ExistingNumber: () =>
            import("@/views/largeScreen/bankScreen/module/existingNumber.vue"),
        // DeviceCase: () => import("@/views/largeScreen/firm/modules/deviceCase.vue"),
        HealthWarning: () =>
            import("@/views/largeScreen/bankScreen/module/healthWarning.vue"),
        Echarts8: () =>
            import("@/views/largeScreen/bankScreen/module/echartsjg.vue"),
        Top: () => import("@/views/largeScreen/bankScreen/module/NewTop.vue"),
        // Echarts6: () =>
        //   import("@/views/largeScreen/insurance/module/echarts3.vue"),
        Echarts4: () =>
            import("@/views/largeScreen/bankScreen/module/echarts4.vue"),
        // Death: () => import("@/views/NewbigScreenenterprise/components/Death.vue"),
        Echartssell: () =>
            import("@/views/largeScreen/bankScreen/module/echartssell.vue"),
        AIDie: () => import("@/views/largeScreen/bankScreen/module/AIDie.vue"),
        Map: () =>
            import("@/views/largeScreen/bankScreen/module/bigScreenMap.vue"),
        // Map: () => import("@/views/largeScreen/bankScreen/module/map.vue"),
    },
})
export default class HelloWorld extends Vue {
    private currentDate: string = "";
    private currentTime: string = "";
    private showModule: boolean = false;

    private refreshModule: string = "";
    private moduleNumber: number = 0;

    private popHeight = 100;
    private popTitle = "";
    private popMessage = "";
    private popHeightStop = 10;
    private popTime: any = "";
    private BnakListData: any[] = [];
    private changeBankName: string = "";
    private ShowBankList: boolean = false;
    private videoSource = "bank";

    private popList = [];

    private refreshOrder = [
        "ExistingNumber",
        "Echarts8",
        "HealthWarning",
        "Echartssell",
        "AIDie",
        "Echarts4",
    ];

    // （受监管养殖场总数/监管数量/存栏数量）-实时盘点-健康预警-非法离开-AI死亡-耳标异常

    private time: any = "";

    private showCitySelection: boolean = false;
    private changeCity: string = "-1";
    private changeName: string = "";

    private form: any = {
        id: "0",
        farmId: "",
        currentType: "",
        pid: "",
        bankId: "",
    };

    private transmitForm: any = {
        id: "0",
        farmId: "",
        currentType: "",
        pid: "",
        bankId: "",
    };

    private groupCodeList = [];
    private typeList = [];
    private ShowVc: boolean = true; //控制是否重新加载页面的变量
    private farmNumber: number = 0;
    private allAmount: number = 0;

    private created(): void {
        let setDate = () => {
            let current = new Date();
            this.currentDate = Util.dateUtil(current);
            this.currentTime = current.toTimeString().split(" ")[0];
            window.setTimeout(setDate, 1000);
        };
        setDate();
    }

    private async mounted() {
        this.getJudgeExistWarninLightAuth();
        this.CheckBankFun();
        let that = this;
        // 获取银行支行大屏视频组织机构

        // document.addEventListener("visibilitychange", function() {
        //   console.log( document.visibilityState );

        //   if(document.visibilityState == 'hidden'){
        //     that.ShowVc = false
        //   }else{
        //     that.ShowVc = true
        //     that.getBankList().then((res:any)=>{//3.1.5
        //       that.getGroupCode(res)
        //     })
        //     that.getSummary();
        //   }
        // });
    }
    private VideoGroupCodeData: any[] = []; //传递的左侧地址数据
    private VideoListData: any = {
        page: {
            totalCount: 0,
        },
    }; //传递的视频列表数据
    private PageObj: any = {
        pageNo: 1,
        pageSize: 4,
    };
    private generateRandomLevelId() {
        // 生成一个随机的唯一标识符，可以根据需要修改长度
        const uniqueId = Math.random()
            .toString(36)
            .substring(2, 10);

        return uniqueId;
    }

    private generateLevelId(data: any) {
        data.forEach((item: any) => {
            // 生成随机的 levelId
            item.levelId = this.generateRandomLevelId();

            // 如果当前项有子项，则递归调用此函数
            if (item.children && item.children.length > 0) {
                this.generateLevelId(item.children);
            }
        });
    }
    private ChildObjVal: any = {}; //子组件传递过来的值
    private VideolaunchData: any[] = []; //传递的左侧地址数据
    private getBigScreenBankNewVideoGroupCodeData(
        animalTypeId: number | string,
        bankId: number | string
    ) {
        new BankModule().getBigScreenBankNewVideoGroupCodeV333(
            animalTypeId,
            bankId,
            (data: any) => {
                this.VideoGroupCodeData = data.data;
                this.generateLevelId(this.VideoGroupCodeData);
                this.VideolaunchData = this.extractLevelIds();
            }
        );
    }
    private extractLevelIds() {
        const levelIds: any = [];

        // 遍历第一层数据
        this.VideoGroupCodeData.forEach((item: any) => {
            // 添加当前项的levelId到数组中
            levelIds.push(item.levelId);
        });
        return levelIds;
    }
    private SetFatherVideoSendData(val: any) {
        //子子组件的emit事件  告诉此组件需要请求数据
        this.ChildObjVal = val;
        this.ChildObjVal.animalTypeId = this.form.currentType;
        this.ChildObjVal.bankId = this.transmitForm.bankId;
        this.PageObj.pageNo = 1; //地址变化之后  将PageObj的 pageNo = 1
        this.getBigScreenBankScreenVideoListData(
            this.ChildObjVal,
            this.PageObj
        ); //请求数据  并传递给子组件
    }
    private getVideoList() {
        //子组件告诉此组件  依据当前组件的数据 获取视频列表的数据  点击更多的emit
        this.PageObj.pageNo = 1; //点击更多 始终使用本页面的数据参数请求  保持 页码为1
        let VideoForm: any = {
            groupCode: this.curentAdcode,
            animalTypeId:
                this.transmitForm.currentType || this.form.currentType,
            farmId: this.form.farmId,
            deviceCode: "",
            bankId: this.transmitForm.bankId,
        };
        this.handlerChildObj();
        this.getBigScreenBankScreenVideoListData(VideoForm, this.PageObj);
    }

    private getNextPage(val: any) {
        this.handlerChildObj();
        if (
            this.PageObj.pageSize * this.PageObj.pageNo <
            this.PageObj.totalCount
        ) {
            this.ChildObjVal.bankId = this.transmitForm.bankId;
            this.PageObj.pageNo++;
            this.getBigScreenBankScreenVideoListData(
                this.ChildObjVal,
                this.PageObj
            );
        }
    }
    private getPrevPage() {
        this.handlerChildObj();
        this.PageObj.pageNo--;
        if (this.PageObj.pageNo >= 1) {
            this.ChildObjVal.bankId = this.transmitForm.bankId;
            this.getBigScreenBankScreenVideoListData(
                this.ChildObjVal,
                this.PageObj
            );
        } else {
            this.PageObj.pageNo = 1;
        }
    }
    private ClearObj(val: any) {
        this.ChildObjVal = val;
    }
    private handlerChildObj() {
        console.log(this.ChildObjVal.groupCode);
        this.ChildObjVal.groupCode != undefined &&
        this.ChildObjVal.groupCode != null
            ? (this.ChildObjVal.groupCode = this.ChildObjVal.groupCode)
            : (this.ChildObjVal.groupCode = this.curentAdcode);
        this.ChildObjVal.farmId
            ? (this.ChildObjVal.farmId = this.ChildObjVal.farmId)
            : (this.ChildObjVal.farmId = this.form.farmId);
        this.ChildObjVal.animalTypeId =
            this.transmitForm.currentType || this.form.currentType;
    }
    private timer: any = null;
    private getSummary() {
        // console.log('---------我请求了贷款汇总接口')
        // 逻辑变更：非养殖场：传groupcode，养殖场：传farmId
        const params: any = {
            animalTypeId: this.form.currentType,
            bankId: this.form.bankId,
        };
        if (this.form.farmId) {
            params.farmId = this.form.farmId;
        } else {
            params.groupCode = this.form.id;
        }
        axios({
            method: "get",
            url: "/animal/web/bigScreenForBankNew/summary",
            params,
        }).then((res: any) => {
            console.log('贷款数据汇总 data: ', res);
            this.farmNumber = res.data.data.farmNumber;
            this.allAmount = res.data.data.allAmount;
            console.log(this.allAmount,'this.allAmount')
            this.$refs.numberCase = document.getElementsByClassName(
                "fields_num"
            )[0];
            let number = this.allAmount;
            let duration = 5000; // 5秒时间
            let intervalTime = 20; // 每次递增间隔时间
            let steps = Math.ceil(duration / intervalTime); // 总步数
            let currentCount = 0;
            let increment = number / steps; // 计算每一步应该增加的基础值
            console.log(this.allAmount,'this.allAmount')
            if (this.allAmount == 0) {
                this.$refs.numberCase.textContent = 0;
                return;
            }
            this.timer = setInterval(() => {
                // 在基础步长的基础上，加入随机性（这里使用 80% 到 120% 的范围）
                let randomFactor = 0.8 + Math.random() * 0.4;
                let stepIncrement = increment * randomFactor;

                currentCount += stepIncrement;

                if (currentCount < number) {
                    this.$refs.numberCase.textContent = Math.floor(
                        currentCount
                    ); // 显示的数字取整
                } else {
                    this.$refs.numberCase.textContent = number; // 到达最终数字
                    clearInterval(this.timer); // 停止计时器
                }
            }, intervalTime);
        });
    }

    private beforeDestroy(): void {
        window.clearInterval(this.time);
        clearInterval(this.timer);
        this.time = "";
    }

    private popRight = -10;

    private setPopMessage(): void {
        if (this.popHeight >= 90) {
            this.popHeight = 10;
        } else {
            this.popHeight += 10;
        }
        let time = window.setInterval(() => {
            if (this.popRight >= 0.5) {
                window.clearInterval(time);
            } else {
                this.popRight += 0.5;
            }
        }, 10);
        this.popTitle = this.popList[0].typeCode;
        this.popMessage = this.popList[0].message;
        this.popList.splice(0, 1);
        this.popPlay();
    }

    private popPlay() {
        window.setTimeout(() => {
            this.popRight = -10;
            if (this.popList.length == 0) {
                this.popTitle = "";
                this.popMessage = "";
                this.popHeightStop = this.popHeight;
                this.popHeight = 100;
                // window.clearInterval(this.popTime)
                this.popTime = "";
            } else {
                this.setPopMessage();
            }
        }, 5000);
    }

    private timeRefresh(): void {
        clearInterval(this.time);
        this.time = setInterval(() => {
            this.refreshModule = this.refreshOrder[this.moduleNumber];
            if (this.moduleNumber == 4) {
                this.getPopData();
            }
            if (this.moduleNumber == 5) {
                this.moduleNumber = 0;
            } else {
                this.moduleNumber += 1;
            }
        }, 10000);
    }

    private getPopData() {
        window.clearInterval(this.popTime);
        this.popTime = "";
        this.popList.splice(0, this.popList.length);
        return new Promise((resolve, reject) => {
            console.log("rigth side interface", this.transmitForm, this.form);
            let params = {
                groupCode: this.transmitForm.id,
                farmId: this.transmitForm.farmId,
                bankId: this.form.bankId,
                animalTypeId:
                    this.transmitForm.currentType || this.form.currentType,
            };
            if (params.groupCode === "") {
                if (this.form.id !== "") {
                    params.groupCode = this.form.id;
                } else {
                    params.groupCode = "0";
                }
            }
            new BasicsClass().BasicGet(
                "/animal/web/bigScreenForBankNew/rightSideMessage",
                params,
                false,
                true,
                (res: any) => {
                    if (this.popList.length > 0) {
                        this.popList.push(...res.data);
                    } else {
                        this.popList.push(...res.data);
                        this.popTitle = this.popList[0].typeCode;
                        this.popMessage = this.popList[0].message;
                        this.popList.splice(0, 1);
                        this.popHeight = this.popHeightStop;
                        let time = window.setInterval(() => {
                            if (this.popRight >= 0.5) {
                                window.clearInterval(time);
                            } else {
                                this.popRight += 0.5;
                            }
                        }, 10);
                        this.popPlay();
                    }
                    // this.popPlay()
                    resolve(" ");
                }
            );
        });
    }

    private authorityAdcode: number = null; //当前账户的地图权限
    private authorityName: string = null; // 当前账户权限对应的名称
    private curentAdcode: number = 0; // 当前区域的 adcode
    private FarmId: any = "";

    private getGroupCode(bankId: string) {
        return new Promise((resolve, reject) => {
            bankGroupCodeNav315(bankId, (res: any) => {
                this.groupCodeList = res.data;
                this.changeName = res.data[0].name;
                // console.log('-------银行大屏 Data：', res);
                if (res.data.length > 0) {
                    this.changeCity = res.data[0].id;
                    this.authorityAdcode = res.data[0].id;
                    this.authorityName = res.data[0].name;
                }
                this.form.id = res.data[0].id;

                let that = this;
                that.handleCity({
                    id: res.data[0].id,
                    gdGroupCode: res.data[0].id,
                    name: res.data[0].name,
                    longitude: res.data[0].longitude,
                    latitude: res.data[0].latitude,
                    farmId: "",
                    pid: "",
                });
                resolve(" ");
            });
        });
    }

    private getTypeSort() {
        return new Promise((resolve, reject) => {
            new BasicsClass().BasicGet(
                "/animal/web/bigScreenForBankNew/pigCattleAndSheepForBank",
                {
                    groupCode: this.form.id,
                    farmId: this.form.farmId,
                    bankId: this.form.bankId,
                },
                false,
                true,
                (res: any) => {
                    this.typeList.splice(0, this.typeList.length);
                    this.form.currentType = res.data[0];
                    this.form.transmitForm = res.data[0];
                    this.getBigScreenBankNewVideoGroupCodeData(
                        res.data[0],
                        this.transmitForm.bankId
                    );
                    // console.log(res)
                    for (let i in res.data) {
                        if (res.data[i] == 1) {
                            this.typeList.push({
                                img: require("@/assets/pig.png"),
                                checkImg: require("@/assets/pigs.png"),
                                id: 1,
                            });
                        } else if (res.data[i] == 2) {
                            this.typeList.push({
                                img: require("@/assets/cow.png"),
                                checkImg: require("@/assets/cows.png"),
                                id: 2,
                            });
                        } else if (res.data[i] == 3) {
                            this.typeList.push({
                                img: require("@/assets/sheep.png"),
                                checkImg: require("@/assets/sheeps.png"),
                                id: 3,
                            });
                        }
                        // if(this.typeList.length == 3){
                        // 	// console.log(this.typeList)
                        // 	if(!this.showModule){
                        // 		this.showModule = true
                        // 	}
                        // 	resolve(' ')
                        // }
                        if (<any>i == res.data.length - 1) {
                            if (!this.showModule) {
                                this.showModule = true;
                                this.timeRefresh();
                            }
                            resolve(" ");
                        }
                    }
                }
            );
        });
    }

    private handleMap(item: any) {
        this.form.currentType = "";
        this.typeList.splice(0, this.typeList.length);
        this.form.id = item.id;
        this.form.farmId = "";
        this.form.pid = "";
        this.changeCity = item.id;
        this.changeName = item.name;
    }

    // 子组件更新地区名称
    private setCurentAreaName(name: string): void {
        this.changeName = name;
    }

    // 子组件更新地区adcode
    private setCurentAdcode(adcode: number) {
        console.log(adcode);
        // console.log('地图组件更新了当前区域，所以我需要设置当前区域的数据', adcode);
        this.curentAdcode =
            adcode === 100000 ? 0 : (this.curentAdcode = adcode);
        // console.log(JSON.parse(JSON.stringify(this.form)));
        this.transmitForm.id = this.curentAdcode.toString();
        this.form.id = this.curentAdcode.toString();
        // this.form = JSON.parse(JSON.stringify(this.form));
        // console.log(JSON.parse(JSON.stringify(this.transmitForm)));
    }

    setType(id) {
        // console.log('setType: id', id);
        this.form.currentType = id;
        // this.refreshMap(this.form.id);
        this.getSummary();
        this.$refs.dpMap.initData(this.form.id, this.form.currentType);
        this.getBigScreenBankNewVideoGroupCodeData(
            id,
            this.transmitForm.bankId
        );
    }

    private refreshMap(item) {
        // 高德新地图同步
        // 根据点击地区节点的ID 去匹配高德地区对应的ID，展开地图

        let id: any = 0;

        if (item.farmId) {
            id = item.pid;
            this.$refs.dpMap.isOutFarm = true;
            this.$refs.dpMap.outFarmName = item.name;

            // console.log('表明这是一个农场，同时我想知道它所属的机构', item, this.curentAdcode);

            if (item.pid === this.curentAdcode) {
                // console.log('表明这是同一个区域下面的农场切换，地图不会变，但是需要变更sysfram接口')
                // this.$refs.dpMap.initData(this.form.pid, this.form.currentType);
                // this.$refs.dpMap.outerTextMarker({ name: item.name, longitude: item.longitude, latitude: item.latitude});
                // console.log('逻辑变更，变成不用树结构的农场信息打点，直接用sysfram返回的点位信息打点，这样，要丢失，一起丢失')
                this.$refs.dpMap.initData(this.form.pid, this.form.currentType);
                return;
            }
        } else {
            id = item.gdGroupCode;

            if (
                this.curentAdcode.toString() === item.id.toString() &&
                item.id.toString().length === 6
            ) {
                // console.log(this.curentAdcode, item.id, item.gdGroupCode)
                // console.log('表明现在是由农场返回到它的父亲一级，因为地图不会变，所以点位也就没更新，需要自己手动设置');

                this.$refs.dpMap.initData(id, this.form.currentType);
                this.$refs.dpMap.outerTextMarker({
                    name: item.name,
                    longitude: item.longitude,
                    latitude: item.latitude,
                });
                return;
            }
        }

        // 根据点击地区节点的ID 去匹配高德地区对应的ID，展开地图
        if (id && id.toString().length === 6) {
            // console.log('表明我点击的是最后一层级，六位地区码');
            // 这个code地图是获取不到下一级区域的，所以无法获取地图节点对象，需要我们自己设置名称和点位信息
            this.$refs.dpMap.setLastLevelObj(
                item.name,
                item.longitude,
                item.latitude
            );
        }

        for (let i = Number(id).toString().length; i < 6; i += 1) {
            id += "0";
        }
        if (id === "000000") id = 100000;
        // console.log('补足id为六位，末尾加0：', id);
        // 点击了树结构，需要同步地图变化
        // this.$refs.dpMap.switch2AreaNode(Number(id), null, id);
        let that = this;

        if (this.isInit) {
            setTimeout(() => {
                this.isInit = false;
                self();
                // console.log('-----初始化地图数据');
            }, 3000);
        } else {
            self();
        }

        function self() {
            // console.log(Boolean(that), 666);
            if (that && that.$refs && that.$refs.dpMap) {
                // console.log('我成功的调用了地图组件', id);
                that.$refs.dpMap.switch2AreaNode(Number(id), null, id);
            } else {
                setTimeout(() => {
                    // console.log('地图还没有加载成功，所以我要一直调用');
                    self();
                }, 100);
            }
        }
    }

    private isInit: boolean = true;

    //给每个有详情的组件使用的groupcode id
    private sendGroupCodeId: string = "";
    private sendFarmId: string = "";
    private sendGroupName: string = "";
    private sendFarmName: string = "";

    //处理直辖市id的函数
    private changeGroupCodeFun(item: any) {
        this.sendGroupCodeId = "";
        this.sendFarmId = "";
        this.sendGroupName = "";
        this.sendFarmName = "";
        if (item.farmId == null) {
            this.sendGroupName = item.name;
            if (item.id == "1101") {
                this.sendGroupCodeId = "11";
            } else if (item.id == "1201") {
                this.sendGroupCodeId = "12";
            } else if (item.id == "3101") {
                this.sendGroupCodeId = "31";
            } else if (item.id == "5001" || item.id == "5002") {
                this.sendGroupCodeId = "50";
            } else {
                this.sendGroupCodeId = item.id;
            }
        } else {
            this.sendGroupCodeId = "";
            this.sendGroupName = "";
            this.sendFarmName = item.name;
            if (item.name == "中国") this.sendFarmName = "";
            this.sendFarmId = item.farmId;
        }
    }

    //修复线上遗留问题 临时做出处理
    private OnlyGroupCode: number = 0;
    private async handleCity(item: any): void {
        (this.OnlyGroupCode = item.id),
            console.log(item.id, this.form.currentType);
        this.$refs.dpMap.initData(item.id, this.form.currentType);
        this.changeGroupCodeFun(item);
        this.form.id = item.id;
        this.form.farmId = item.farmId;
        this.form.pid = item.pid;
        this.form.currentType = "";
        // 千万不要在这里面修改 transmitForm 的属性，因为非常多的子组件watch了这个对象，这边修改了，会造成子组件监听变化，调用后台接口
        // 而参数属性还没有赋值完全，会报错，而且还会重复调用两次，这行代码不删，注释引起警戒
        // this.transmitForm.farmId = item.farmId;

        this.typeList.splice(0, this.typeList.length);
        this.changeCity = item.id;
        this.changeName = item.name;

        this.showCitySelection = false;
        this.changeTwoFun();

        // if (item.farmId) {
        //   this.refreshMap(item.pid)
        // } else {
        //   this.refreshMap(item.gdGroupCode);
        // }

        this.refreshMap(item);
    }

    @Watch("form.currentType")
    private getDate(newData, oldData) {
        console.log(newData);
        if (newData != "") {
            this.transmitForm.id = this.form.id;
            this.transmitForm.farmId = this.form.farmId;
            this.transmitForm.currentType = this.form.currentType;
            this.transmitForm.pid = this.form.pid;
            this.transmitForm.bankId = this.form.bankId;

            window.clearInterval(this.time);
            this.time = "";
            this.moduleNumber = 0;
            this.chooseList();
            // this.changeTwoFun()
            this.timeRefresh();
            this.getPopData();
        }
    }

    private goback(): void {
        // new BasicsClass().BasicGet('/animal/web/menu/currentMenus',{},false,true,(res:any)=>{
        // let url = res.data[0].children[0].url;
        // const path = this.$route.path === url ? '/homeep' : url;
        this.$router.push("/Welcome");
        // })
    }

    private videoList: any = [];

    private chooseBigData: BigData = new BigData();
    private page: any = {
        pageSize: 4,
        pageNo: 1,
        totalCount: 0,
    };

    private AddListNumber: number = 4;
    private TopFlag: boolean = false;
    private chooseList() {
        this.TopFlag = false;
        return new Promise((resolve, reject) => {
            this.chooseBigData.chooseList3(
                this.transmitForm,
                this.page,
                (data: any) => {
                    this.page.totalCount = data.page.totalCount;
                    let listArr: any[] = [];
                    if (data.data.length != 0) {
                        for (
                            let i = 0;
                            i < data.data.length;
                            i += this.AddListNumber
                        ) {
                            listArr.push(
                                data.data.slice(i, i + this.AddListNumber)
                            );
                        }
                    } else {
                        listArr = [];
                    }
                    //处理数组
                    this.videoList = listArr;
                    this.TopFlag = true;
                    resolve(" ");
                },
                (errData: any) => {
                    this.videoList = [];
                }
            );
        });
    }
    private getBigScreenBankScreenVideoListData(form: any, page: any) {
        new BankModule().getBigScreenBankNewVideoListV333(
            form,
            page,
            (data: any) => {
                this.VideoListData = data;
                this.PageObj = data.page;
            }
        );
    }

    private nextList() {
        this.chooseBigData.chooseList3(
            this.transmitForm,
            this.page,
            (data: any) => {
                let listArr: any[] = [];
                if (data.data.length != 0) {
                    for (
                        let i = 0;
                        i < data.data.length;
                        i += this.AddListNumber
                    ) {
                        listArr.push(
                            data.data.slice(i, i + this.AddListNumber)
                        );
                    }
                } else {
                    listArr = [];
                }
                //处理数组
                this.videoList = listArr;
                this.TopFlag = true;
            },
            (errData: any) => {
                this.videoList = [];
            }
        );
    }

    private prevList() {
        this.chooseBigData.chooseList3(
            this.transmitForm,
            this.page,
            (data: any) => {
                let listArr: any[] = [];
                if (data.data.length != 0) {
                    for (
                        let i = 0;
                        i < data.data.length;
                        i += this.AddListNumber
                    ) {
                        listArr.push(
                            data.data.slice(i, i + this.AddListNumber)
                        );
                    }
                } else {
                    listArr = [];
                }
                //处理数组
                this.videoList = listArr;
                this.TopFlag = true;
            },
            (errData: any) => {
                this.videoList = [];
            }
        );
    }

    prev() {
        this.page.pageNo--;
        if (this.page.pageNo >= 1) {
            this.prevList();
        } else {
            this.page.pageNo = 1;
        }
    }

    next() {
        if (this.page.pageSize * this.page.pageNo < this.page.totalCount) {
            this.page.pageNo++;
            this.nextList();
        }
    }

    private getBankList() {
        return new Promise((resolve: Function) => {
            getOldBankCurrentBankfiliale315((data: any) => {
                this.BnakListData = data.data;
                this.changeBankName = data.data[0].name;
                this.transmitForm.bankId = data.data[0].id;
                this.form.bankId = data.data[0].id;
                resolve(data.data[0].id);
            });
        });
    }

    private HandlerBank(item: any) {
        this.transmitForm.bankId = item.id;
        this.form.bankId = item.id;
        this.changeBankName = item.name;
        this.ShowBankList = false;
        this.showCitySelection = true;
        this.groupCodeList = [];
        this.changeName = "";
        //将所有 子组件的form  和本组件的form的groupCode 和 farmId进行置空
        this.ClearFormItem();
        this.changeTwoFun();
        bankGroupCodeNav315(item.id, (data: any) => {
            //点击银行之后 请求接口并 赋值
            this.groupCodeList = data.data;
            this.OnlyGroupCode = data.data[0].id;
            // this.$refs.dpMap.initData(this.form.id, this.form.currentType);
            // this.changeName = data.dataW[0].name
        });
    }

    private ClearFormItem() {
        //清空部分属性
        this.form.farmId = "";
        this.form.pid = "";
        this.form.id = "0";
        this.form.groupCode = "0";
        this.transmitForm.farmId = "";
        this.transmitForm.pid = "";
        this.transmitForm.id = "0";
        this.transmitForm.groupCode = "0";
    }

    private CheckBankFun() {
        this.getBankList().then((res: any) => {
            //3.1.5
            this.getGroupCode(res); //获取左侧选择的 地址
            this.getTypeSort().then(() => {
                this.getSummary(); //获取贷款数据汇总数据
                this.chooseList(); //获取视频监管数据
            });
            this.timeRefresh();
            this.getPopData();
            console.log(this.form);
            console.log(this.transmitForm);
        });
    }
    private changeTwoFun() {
        this.getTypeSort().then(() => {
            this.chooseList(); //获取视频监管数据
            this.getSummary(); //获取贷款数据汇总数据
            this.chooseList(); //获取视频监管数据
        });
        this.timeRefresh();
        this.getPopData();
        console.log(this.form);
        console.log(this.transmitForm);
    }

    private showWarningFlag = false; //是否具有闪烁灯权限

    // api 是否具有闪烁灯权限接口
    private getJudgeExistWarninLightAuth() {
        judgeExistWarninLightAuth("bankScreen", (res: any) => {
            this.showWarningFlag = res.data;
        });
    }
}
